import { Map as MapIcon } from '@griegconnect/krakentools-react-icons'
import { Navigation, requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import React from 'react'
import { AccessDependencies, PERMISSIONS } from '@app/permissions'
import { tenantsUrlMatchPathPrefix } from '../../constants'
import { PinDrop } from '@mui/icons-material'

export const PATH_ASSETS_MAP = tenantsUrlMatchPathPrefix + '/assets'

export const assetMapNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: 'AssetsTester',
    page: React.createElement(React.lazy(() => import('./AssetsMap'))),
    menu: {
      icon: <PinDrop />,
    },
    search: { mode: 'live' },
    access: (d) => requiresAllOfPermissions([PERMISSIONS.layers.read, PERMISSIONS.maps.read])(d),
    path: PATH_ASSETS_MAP,
  },
]
